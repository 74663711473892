

import React, { Component } from 'react'

import loading from '../assets/images/loading.gif'

class Contact extends Component {
    constructor(props){
        super(props)
        this.submitEmail = this.submitEmail.bind(this)
        this.clearForm = this.clearForm.bind(this)
        this.domainToken = "406cd5c9-0f64-479d-b535-3439f4139b0b"
    }
    
    state = {
        name: '',
        email: '',
        msg: '',
        sending: false,
        feedback: ''
    }
    
    updateText(key,val){
        this.setState({ [`${key}`]: val })
    }

    submitEmail(ev){
        ev.preventDefault()
        this.setState({ sending:true },()=>{
            /* SmtpJS.com - v2.0.1 */
            let Email = {
                send: function (e, o, t, n, a, s, r, c) {
                    var d = Math.floor(1e6 * Math.random() + 1),
                    i = "From=" + e;
                    i += "&to=" + o
                    i += "&Subject=" + encodeURIComponent(t)
                    i += "&Body=" + encodeURIComponent(n)
                    // void 0 === a.token ? (i += "&Host=" + a, i += "&Username=" + s, i += "&Password=" + r, i += "&Action=Send") : (i += "&SecureToken=" + a.token, i += "&Action=SendFromStored", c = a.callback)
                    i += "&SecureToken=" + a.token
                    i += "&Action=SendFromStored"
                    c = a.callback
                    i += "&cachebuster=" + d
                    Email.ajaxPost("https://smtpjs.com/v2/smtp.aspx?", i, c)
                },
                sendWithAttachment: function (e, o, t, n, a, s, r, c, d) {
                    var i = Math.floor(1e6 * Math.random() + 1),
                    m = "From=" + e;
                    m += "&to=" + o
                    m += "&Subject=" + encodeURIComponent(t)
                    m += "&Body=" + encodeURIComponent(n)
                    m += "&Attachment=" + encodeURIComponent(c)
                    // void 0 === a.token ? (m += "&Host=" + a, m += "&Username=" + s, m += "&Password=" + r, m += "&Action=Send") : (m += "&SecureToken=" + a.token, m += "&Action=SendFromStored")
                    m += "&SecureToken=" + a.token
                    m += "&Action=SendFromStored"
                    m += "&cachebuster=" + i
                    Email.ajaxPost("https://smtpjs.com/v2/smtp.aspx?", m, d)
                },
                ajaxPost: function (e, o, t) {
                    var n = Email.createCORSRequest("POST", e);
                    n.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
                    n.onload = function () {
                        var e = n.responseText; void 0 !== t && t(e)
                    }
                    n.send(o)
                },
                ajax: function (e, o) {
                    var t = Email.createCORSRequest("GET", e);
                    t.onload = function () {
                        var e = t.responseText; void 0 !== o && o(e)
                    }
                    t.send()
                },
                createCORSRequest: function (e, o) {
                    var t = new XMLHttpRequest; return "withCredentials" in t ? t.open(e, o, !0) : "undefined" !== typeof XDomainRequest ? (t = new XDomainRequest).open(e, o) : t = null, t
                }
            }

            let { name,email,msg } = this.state
            let from = "gcwcontractors@gmail.com"

            function isValidEmail(email){
                let patt = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                let res = patt.test(email)
                return res
            }

            if (name.length > 0 && isValidEmail(email) && msg.length > 0) {
                Email.send(
                    from,
                    from,
                    `Message from ${name} via GCW Website Contact Form`,
                    `${name}
                    ${email}
                    
                    ${msg}`,
                    {
                        token: this.domainToken,
                        callback: (res) => this.handleSendResponse(res)
                    }
                )  
            }   else {
                this.setState({
                    feedback: 'Name, email, and message are required fields. Please complete the form before re-submitting.',
                    sending: false
                })
            }       
        })
    }

    handleSendResponse(res){
        this.stopSending(res === 'OK' ? true : false)
    }

    stopSending(success){
        this.setState({ sending:false },()=>{
            if (success) {
                this.setState({ feedback:"Success. We'll be in touch!" },()=>{
                    setTimeout(()=>{
                        this.clearForm()
                    },4000)
                })
            } else {
                this.setState({
                    feedback:"Oops, your email did not go through. Before refreshing the page to try sending it again, please copy your message so it's not lost."
                })
            }
        })
    }

    clearForm(ev){
        ev && ev.preventDefault && ev.preventDefault()
        this.setState({
            name: '',
            email: '',
            msg: '',
            feedback: ''          
        })
    }
    
    render(){
        let { name,email,msg,sending,feedback } = this.state
        let style = {
            display: 'flex', justifyContent: 'center', alignItems: 'center', width:200
        }
        return (
            <section id="contact">
                <div className="inner">
                    <section>
                        <form>
                            <div className="field half first">
                                <label htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" value={name} onChange={(ev)=>this.updateText('name',ev.target.value)} />
                            </div>
                            <div className="field half">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" value={email} onChange={(ev)=>this.updateText('email',ev.target.value)} />
                            </div>
                            <div className="field">
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" rows="6" value={msg} onChange={(ev)=>this.updateText('msg',ev.target.value)}></textarea>
                            </div>
                            <ul className="actions">
                                <li>
                                    <button type="submit" className="special" onClick={this.submitEmail} style={style} disabled={ sending ? true : false}>
                                        { sending ? <img src={loading} width="30" height="30" /> : 'Send Message' }
                                    </button>
                                </li>
                                <li><input type="submit" value="Clear" onClick={this.clearForm} /></li><br />
                                <li>{ feedback && feedback }</li>
                            </ul>
                        </form>
                    </section>
                    <section className="split">
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-envelope"></span>
                                <h3>Email</h3>
                                <a href="mailto:gcwcontractors@gmail.com">gcwcontractors@gmail.com</a>
                            </div>
                        </section>
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-phone"></span>
                                <h3>Phone</h3>
                                <a href="tel:8035267926">803-526-7926</a>
                            </div>
                        </section>
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-home"></span>
                                <h3>Coverage Area</h3>
                                <span>
                                    South Carolina, North Carolina,<br />
                                    Virginia, Georgia, & Florida
                                </span>
                            </div>
                        </section>
                    </section>
                </div>
            </section>
        )
    }
}

export default Contact



import React from 'react'

import wright from '../assets/images/wright.jpg'
import castro from '../assets/images/castro.png'
import tnt from '../assets/images/tnt-construction.png'

const Profile = ({img,double,name,bp0,bp1,bp2,bp3}) => (
    <div style={{ display: 'block', flexFlow: 'column wrap', width: `${double ? 600 : 300}`, alignItems: 'center'  }}>
        <div style={{ textAlign:'center' }}>
            <img src={img} width={ double ? 465 : 200 } height="270"  />
            <h3>{ name }</h3>
        </div>
        <ul>
            <li>{ bp0 }</li>
            <li>{ bp1 }</li>
            <li>{ bp2 }</li>
            { bp3 && <li>{ bp3 }</li> }
        </ul>
    </div>
)

const About = props => (
    <section id="two" style={{ backgroundColor: '#2a2f4a' }}>
        <div className="inner">
            <header className="major">
                <h2>Our Team</h2>
            </header>
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
                <Profile img={wright} 
                    name="Alex Wright"
                    bp0="Site Manager, Operations"
                    bp1="Real Estate Services"
                    bp2="BA, New Mexico State University"/>
                <Profile img={castro}
                    name="Chris Castro"
                    bp0="City/County Permitting"
                    bp1="Procurement & Sourcing"
                    bp2="MBA, The Ohio State University"/>
                <Profile img={tnt}
                    name="Licensed General Contractors"
                    bp0="Vendor Relations"
                    bp1="Contract Negotiations"
                    bp2="Closings & Legal" double/>
            </div>
        </div>
    </section> 
)

export default About


import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

class Header extends Component {
    state = {
        color: '#9bf1ff'
    }
    
    render(){
        let { color } = this.state
        return (
            <header id="header" className="alt">
                <Link to="/" className="logo"
                    onMouseOver={ () => this.setState({ color: '#ffffff' }) }
                    onMouseOut={ () => this.setState({ color: '#9bf1ff' }) }>
                        <strong style={{ backgroundColor: color }}>GCW</strong>
                        <span style={{ color }}> Construction</span>
                </Link>
                <nav>
                    <a className="menu-link" onClick={this.props.onToggleMenu} href="javascript:;">Menu</a>
                </nav>
            </header>        
        )
    }
}

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header

// const ltBlue = '#9bf1ff'

// const Header = (props) => (
//     <header id="header" className="alt">
//         <Link to="/" className="logo"><strong style={{ backgroundColor: ltBlue }}>GCW</strong> <span style={{ color: ltBlue }}>Construction</span></Link>
//         <nav>
//             <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
//         </nav>
//     </header>
// )
